import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/builds/proscom/hse-design/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Icon, IconActionPlus, IconGlobalCalendar, IconStatusAttention, Size, Space } from '@hse-design/react';
import { Playground, Props } from 'docz';
import { ComponentLinks } from '../common';
import { colorAccentRedDark } from '@hse-design/tokens';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "icon"
    }}>{`Icon`}</h1>
    <pre><code parentName="pre" {...{
        "className": "language-ts"
      }}>{`import { Icon } from '@hse-design/react';
`}</code></pre>
    <ComponentLinks name={'Icon'} figma={'https://www.figma.com/file/raJ1JI1lfRDJ0ctGj7Rpgf/hse_icons_library?node-id=1%3A2'} storybook={'/?path=/story/icons-icon--playground'} vueStorybook={'/?path=/story/icons-icon--playground'} mdxType="ComponentLinks" />
    <h2 {...{
      "id": "описание"
    }}>{`Описание`}</h2>
    <p>{`Icon — это компонент для вставки иконок. Он задает правильные размеры иконке и обеспечивает использование корректной вариации.`}</p>
    <p>{`Для отображения необходимой иконки нужно передать в проп `}<inlineCode parentName="p">{`icon`}</inlineCode>{` ссылку на компонент иконки (см. пример ниже).`}</p>
    <p>{`Все доступные иконки можно посмотреть на `}<a parentName="p" {...{
        "href": "/icons"
      }}>{`странице доступных иконок`}</a>{`.
Вы можете использовать компонент Icon с собственными иконками, как описано на той же странице.`}</p>
    <p>{`Иконки доступны в трех размерах. Обратите внимание, что размер иконки достигается не просто масштабированием, а адаптацией.
Компонент Icon автоматически выбирает необходимый размер иконки.`}</p>
    <p>{`Иконку можно покрасить в любой цвет. Подробнее `}<a parentName="p" {...{
        "href": "#%D1%86%D0%B2%D0%B5%D1%82-%D0%B8%D0%BA%D0%BE%D0%BD%D0%BA%D0%B8"
      }}>{`в примере ниже`}</a></p>
    <Playground __position={1} __code={'<Icon icon={IconActionPlus} />'} __scope={{
      props,
      DefaultLayout,
      Icon,
      IconActionPlus,
      IconGlobalCalendar,
      IconStatusAttention,
      Size,
      Space,
      Playground,
      Props,
      ComponentLinks,
      colorAccentRedDark,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Icon icon={IconActionPlus} mdxType="Icon" />
    </Playground>
    <h2 {...{
      "id": "примеры"
    }}>{`Примеры`}</h2>
    <h3 {...{
      "id": "размеры-иконки"
    }}>{`Размеры иконки`}</h3>
    <p>{`У иконок 3 размера: `}<inlineCode parentName="p">{`small`}</inlineCode>{`, `}<inlineCode parentName="p">{`medium`}</inlineCode>{`, `}<inlineCode parentName="p">{`large`}</inlineCode>{`. Передайте в проп `}<inlineCode parentName="p">{`size`}</inlineCode>{` нужный.`}</p>
    <p>{`По умолчанию используется размер `}<inlineCode parentName="p">{`medium`}</inlineCode>{`.`}</p>
    <Playground __position={2} __code={'<Icon icon={IconGlobalCalendar} size=\"small\" />\n<Space size={Size.small_2x} />\n<Icon icon={IconGlobalCalendar} />\n<Space size={Size.small_2x} />\n<Icon icon={IconGlobalCalendar} size=\"large\" />'} __scope={{
      props,
      DefaultLayout,
      Icon,
      IconActionPlus,
      IconGlobalCalendar,
      IconStatusAttention,
      Size,
      Space,
      Playground,
      Props,
      ComponentLinks,
      colorAccentRedDark,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Icon icon={IconGlobalCalendar} size="small" mdxType="Icon" />
  <Space size={Size.small_2x} mdxType="Space" />
  <Icon icon={IconGlobalCalendar} mdxType="Icon" />
  <Space size={Size.small_2x} mdxType="Space" />
  <Icon icon={IconGlobalCalendar} size="large" mdxType="Icon" />
    </Playground>
    <h3 {...{
      "id": "цвет-иконки"
    }}>{`Цвет иконки`}</h3>
    <p>{`Иконки автоматически красятся в текущий цвет, заданный через css (`}<inlineCode parentName="p">{`currentColor`}</inlineCode>{`). По умолчанию иконка будет использовать цвет (css-свойство `}<inlineCode parentName="p">{`color`}</inlineCode>{`) ближайшего родителя.`}</p>
    <p>{`Задать цвет иконки вы можете с помощью пропа `}<inlineCode parentName="p">{`color`}</inlineCode>{` или через атрибут `}<inlineCode parentName="p">{`style`}</inlineCode>{` (`}<inlineCode parentName="p">{`style={{ color: colorAccentRedDark }}`}</inlineCode>{`). Рекомендуем использовать цвета из `}<a parentName="p" {...{
        "href": "/src-tokens-color"
      }}>{`токенов цветов`}</a>{`.`}</p>
    <Playground __position={3} __code={'<Icon icon={IconStatusAttention} color={colorAccentRedDark} />'} __scope={{
      props,
      DefaultLayout,
      Icon,
      IconActionPlus,
      IconGlobalCalendar,
      IconStatusAttention,
      Size,
      Space,
      Playground,
      Props,
      ComponentLinks,
      colorAccentRedDark,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Icon icon={IconStatusAttention} color={colorAccentRedDark} mdxType="Icon" />
    </Playground>
    <h2 {...{
      "id": "пропы"
    }}>{`Пропы`}</h2>
    <Props of={Icon} mdxType="Props" />
    <p>{`Все остальные пропы пробрасываются на корневой элемент `}<inlineCode parentName="p">{`svg`}</inlineCode>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      